var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "fill-width align-self-center"
  }, [_c('v-card', {
    staticClass: "d-flex align-center fill-width",
    attrs: {
      "color": "primary",
      "flat": "",
      "height": "50",
      "tile": ""
    }
  }, [_c('a', {
    staticClass: "pa-5",
    attrs: {
      "href": "https://testapp.io"
    }
  }, [_c('img', {
    attrs: {
      "src": '/media/logos/logo_light.svg',
      "height": "30",
      "alt": ""
    }
  })])])], 1), _c('v-container', {
    staticClass: "my-12 text-center"
  }, [_vm.mobile !== 'unknown' ? _c('v-row', [_vm.loading ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }), _c('div', {
    staticClass: "mt-4"
  }, [_vm._v("Please wait...")])], 1) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mt-4"
  }, [_c('inline-svg', {
    staticClass: "mb-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/check.svg"
    }
  }), _vm._v(" If the app didn't open or redirect you to the store page, "), _c('a', {
    attrs: {
      "href": _vm.installLink()
    }
  }, [_vm._v("click here")]), _vm._v(" to install the app ")], 1)])], 1) : _c('v-row', {
    staticClass: "mb-15",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "pa-10"
  }, [[_c('v-col', {
    staticClass: "text-center titleFont bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Open this page from your phone ")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v("Or scan this QR code from your phone to proceed")]), _c('qrcode', {
    attrs: {
      "value": _vm.currentURL,
      "options": {
        errorCorrectionLevel: 'Q',
        width: 200
      }
    }
  })], 1)]], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }