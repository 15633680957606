<template>
  <div>
    <div class="fill-width align-self-center">
      <v-card
        color="primary"
        flat
        class="d-flex align-center fill-width"
        height="50"
        tile
      >
        <a href="https://testapp.io" class="pa-5">
          <img :src="'/media/logos/logo_light.svg'" height="30" alt />
        </a>
      </v-card>
    </div>
    <v-container class="my-12 text-center">
      <v-row v-if="mobile !== 'unknown'">
        <v-col cols="12" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div class="mt-4">Please wait...</div>
        </v-col>
        <v-col cols="12" v-else>
          <div class="mt-4">
            <inline-svg
              class="mb-6 svg-icon empty_icon"
              src="/media/placeholders/check.svg"
            />
            If the app didn't open or redirect you to the store page,
            <a :href="installLink()">click here</a> to install the app
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-15" v-else>
        <v-col cols="12" md="8">
          <v-card class="pa-10">
            <template>
              <v-col class="text-center titleFont bold-text" cols="12">
                Open this page from your phone
              </v-col>
              <!--              <v-col class="text-left" cols="12">-->
              <!--                <v-alert label outlined color="red">-->
              <!--                  <span v-html="`Please open this link from your ${$route.query.platform} device`"></span>-->
              <!--                </v-alert>-->
              <!--              </v-col>-->
              <v-col class="text-center" cols="12">
                <div>Or scan this QR code from your phone to proceed</div>
                <qrcode
                  :value="currentURL"
                  :options="{
                    errorCorrectionLevel: 'Q',
                    width: 200,
                  }"
                ></qrcode>
              </v-col>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      releaseUrl: "",
      appId: "",
      releaseId: "",
      mobile: "",
      currentURL: window.location.href,
    };
  },
  created() {
    this.mobile = this.getMobileOperatingSystem;
    this.appId = this.$route.query.app_id;
    this.releaseId = this.$route.query.release_id;
    this.teamId = this.$route.query.team_id;
  },
  mounted() {
    this.openApp(this.mobile);
  },
  methods: {
    openApp(mobile) {
      setTimeout(() => {
        if (mobile !== "unknown") {
          if (mobile === "android") {
            this.openAndroidLink();
          } else {
            this.openIOSApp();
          }
        }
        this.loading = false;
      }, 2000);
    },
    openIOSApp() {
      window?.location?.replace(
        this.releaseId
          ? `testappio://team/${this.teamId}/app/${this.appId}/release/${this.releaseId}`
          : `testappio://team/${this.teamId}/app/${this.appId}`
      );
      setTimeout(function () {
        window?.location?.replace(
          "https://apps.apple.com/us/app/testapp-io/id1518972541"
        );
      }, 2000);
    },
    openAndroidLink() {
      const apkLink = `Intent;scheme=testappio;package=testapp.io;S.browser_fallback_url=https%3A%2F%2Fportal.testapp.io%2Fstart;end`;
      const url = this.releaseId
        ? `intent://team/${this.teamId}/app/${this.appId}/release/${this.releaseId}#${apkLink}`
        : `intent://team/${this.teamId}/app/${this.appId}#${apkLink}`;
      window.open(url, "_self");
    },
    installLink() {
      return `${location.origin}/start`;
    },
  },
};
</script>
